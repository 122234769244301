import React, { Component } from "react"
import anime from "animejs/lib/anime.es.js"

import styles from "./social-item.module.scss"

class SocialItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
    }
  }

  componentDidMount() {
    const transition = anime.timeline({
      easing: "easeOutCubic",
      duration: 1500,
      autoplay: false,
    })

    transition
      .add({
        targets: this.refs.item.querySelectorAll(`.${styles.text} i`),
        duration: 200,
        bottom: ["0px", "12px"],
        opacity: [1, 0],
        easing: "easeInOutCirc(1, .5)",
        delay: anime.stagger(20),
      })
      .add(
        {
          targets: this.refs.item.querySelectorAll(`.${styles.overlay} i`),
          duration: 200,
          bottom: ["-12px", "0px"],
          opacity: [0, 1],

          easing: "easeInOutCirc(1, .5)",
          delay: anime.stagger(20),
        },
        "-=300"
      )
      .add(
        {
          targets: this.refs.item.querySelector(`.${styles.line}`),
          duration: 200,
          width: ["0%", "100%"],
          // opacity: [0, 1],
          // background: ["#000", "#fff"],
          easing: "easeInOutCirc(1, .5)",
        },
        "-=350"
      )

    this.setState({
      transition: transition,
    })
  }

  hoverIn = () => {
    if (this.state.transition.reversed) {
      this.state.transition.reverse()
      this.state.transition.play()
    } else {
      this.state.transition.play()
    }
  }
  hoverOut = () => {
    if (!this.state.transition.reversed) {
      this.state.transition.reverse()
      this.state.transition.play()
    }
  }

  render() {
    const { link, text } = this.props
    const letters = [...text]
    return (
      <a
        href={link}
        rel="nofollow noreferrer"
        alt={text}
        ref="item"
        target="_blank"
        className={styles.item}
        onMouseEnter={this.hoverIn}
        onMouseLeave={this.hoverOut}
        onFocus={this.hoverIn}
        onBlur={this.hoverOut}
      >
        <span className={styles.text}>
          {letters.map((letter, i) => {
            return <i key={i}>{letter}</i>
          })}
        </span>
        <span className={styles.overlay}>
          {letters.map((letter, i) => {
            return <i key={i}>{letter}</i>
          })}
        </span>
        <span className={styles.line}></span>
      </a>
    )
  }
}

export default SocialItem
