/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// TODO: Add images from seo prop
// TODO: Add JsonLD Breadcrumblist for Main page (navigation)
// TODO: add JsonLD Article/Blogposting for Blogs
// TODO: Change OpenGraph images to Local files
function SEO({ seo, description, lang, meta, isBlogPost, author }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription =
    (seo && seo.description) || site.siteMetadata.description
  const metaTitle = (seo && seo.title) || site.siteMetadata.title
  let metaImage = `https://cms.keytoe.nl/resources/uploads/2019/02/keytoe-linkedin-toen-nu.jpg`
  if (seo.image && seo.image.src !== null && seo.image.src.localFile) {
    metaImage = `${site.siteMetadata.siteUrl}${seo.image.src.localFile.childImageSharp.fluid.src}`
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s · ${
        seo && seo.siteName ? seo.siteName : site.siteMetadata.title
      }`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:locale`,
          content: "nl_NL",
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: isBlogPost ? `article` : `website`,
        },
        {
          property: `og:site_name`,
          content: `Keytoe`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:image:secure_url`,
          content: metaImage,
        },
        {
          property: `og:image:width`,
          content: `1280`,
        },
        {
          property: `og:image:height`,
          content: `853`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `article:author`,
          content: author,
        },
        {
          name: `fb:pages`,
          content: "257365850953383",
        },
        {
          name: `fb:app_id`,
          content: "382970922453219",
        },
        {
          name: `google-site-verification`,
          content: "rTy5UtwM-3PTwp-BiC--R9-FM1uAjw4UKDZibsegZaY",
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"WebSite","@id":"https://www.keytoe.nl/#website","url":"https://www.keytoe.nl/","name":"Keytoe","potentialAction":{"@type":"SearchAction","target":"https://www.keytoe.nl/?s={search_term_string}","query-input":"required name=search_term_string"}`}</script>
      <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"Organization","url":"https://www.keytoe.nl/","contactPoint" : [{ "@type" : "ContactPoint","telephone" : "+31103216074","contactType" : "customer service"}],"sameAs":["https://www.facebook.com/keytoe.nl","https://www.instagram.com/keytoenl/","https://www.linkedin.com/company-beta/2398465/","https://twitter.com/keytoe"],"@id":"https://www.keytoe.nl/#organization","name":"Keytoe","logo":"https://www.keytoe.nl/wp-content/uploads/2017/03/keytoe_logo_RGB.png"}`}</script>
      <script type="application/ld+json">{`{
      "@context":"http://schema.org",
      "@type":"ItemList",
      "itemListElement":[
        {
          "@type": "SiteNavigationElement",
          "position": 1,
          "name": "Cases",
          "description": "Nou laat eens zien wat ken dan?",
          "url":"https://keytoe.nl"
        },
        {
          "@type": "SiteNavigationElement",
          "position": 2,
          "name": "Keytoe K",
          "description": "Meer vrijheid op werk?",
          "url":"https://keytoe.nl/k"
        },
        {
          "@type": "SiteNavigationElement",
          "position": 3,
          "name": "Keytoe Y",
          "description": "Maare hoe werken jullie dan?",
          "url":"https://keytoe.nl/y"
        },
        {
          "@type": "SiteNavigationElement",
          "position": 4,
          "name": "Meetup",
          "description": "Kan ik niet ff komen kijken?",
          "url":"https://keytoe.nl/meetup"
        },
                {
          "@type": "SiteNavigationElement",
          "position": 4,
          "name": "Blog",
          "description": "Hebbie niet iets te lezen?",
          "url":"https://keytoe.nl/blog"
        }
      ]
    }`}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `nl`,
  meta: [],
  description: ``,
  author: `Keytoe`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  author: PropTypes.string,
}

export default SEO
