import React, { Component } from "react"
import { Link } from "gatsby"
import anime from "animejs/lib/anime.es.js"
import Image from "gatsby-image"
import ArrowRight from "assets/icons/arrow-right.svg"

import styles from "./navigation-item.module.scss"
//TODO: Transition on item click
class NavigationItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
      transitionPre: null,
      transitionImage: null,
      transitionOverlay: null,
      transitionArrow: null,
      transitionInit: null,
      width: null,
    }
  }
  componentDidMount() {
    this.calculateSize()
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", this.calculateSize)
    }
    const transitionInit = anime({
      targets: this.refs.item,
      // duration: 30000,
      translateY: [100, 0],
      opacity: [0, 1],
      // easing: "easeInOutCirc(1, .5)",
      easing: "spring(1, 80, 10, 0)",
      delay: this.props.details.delay,
      endDelay: this.props.details.delay,
    })

    let padding = 124
    if (typeof window !== `undefined`) {
      if (window.innerWidth <= 768) {
        padding = 32
      }
    }

    const transition = anime({
      targets: this.refs.item,
      duration: 300,
      paddingTop: padding,
      paddingBottom: padding,
      easing: "easeInOutCirc(1, .5)",
      // easing: "spring(.05, 95, 17, 0)",
      autoplay: false,
    })
    let preFontSize = "6.25em"
    if (typeof window !== `undefined`) {
      if (window.innerWidth <= 1024) {
        preFontSize = "3em"
      }
    }

    const transitionPre = anime({
      targets: this.refs.item.querySelector(`.${styles.pre}`),
      duration: 200,
      delay: 100,
      fontSize: ["1.875em", preFontSize],
      letterSpacing: ["4px", "0px"],
      // easing: "spring(.2, 60, 10, 0)",
      easing: "easeInOutCirc(1, .5)",
      autoplay: false,
    })
    anime.set(this.refs.item.querySelector(`.${styles.image}`), {
      right: this.props.details.imagePosition,
    })
    const transitionImage = anime({
      targets: this.refs.item.querySelector(`.${styles.image}`),
      duration: 400,
      delay: 50,
      opacity: [0, 1],
      translateX: [100, 0],
      easing: "easeInOutCirc(1, .5)",
      autoplay: false,
    })
    const transitionOverlay = anime({
      targets: this.refs.item.querySelector(`.${styles.overlay}`),
      duration: 700,
      delay: 100,
      width: ["0%", "100%"],
      background: [this.props.details.color, this.props.details.color],
      // translateX: ["-100%", "0%"],
      // scale: [0, 2],
      // borderRadius: ["100%", "0%"],
      easing: "easeInOutCirc",
      autoplay: false,
    })
    const transitionArrow = anime({
      targets: this.refs.item.querySelector(`.${styles.arrow}`),
      duration: 400,
      delay: 100,
      opacity: [0, 1],
      translateX: ["-100%", "0%"],
      // scale: [0, 2],
      // borderRadius: ["100%", "0%"],
      easing: "easeInOutCirc",
      autoplay: false,
    })
    this.setState({
      transition: transition,
      transitionPre: transitionPre,
      transitionImage: transitionImage,
      transitionOverlay: transitionOverlay,
      transitionArrow: transitionArrow,
      transitionInit: transitionInit,
    })
  }

  hoverIn = event => {
    if (this.state.width > 1024) {

      if (this.state.transition.reversed) {
        this.state.transition.reverse()
        this.state.transitionPre.reverse()
        this.state.transitionImage.reverse()
        this.state.transitionOverlay.reverse()
        this.state.transitionArrow.reverse()
        this.state.transition.play()
        this.state.transitionPre.play()
        this.state.transitionImage.play()
        this.state.transitionOverlay.play()
        this.state.transitionArrow.play()
      } else {
        this.state.transition.play()
        this.state.transitionPre.play()
        this.state.transitionImage.play()
        this.state.transitionOverlay.play()
        this.state.transitionArrow.play()
      }
    }
  }
  hoverOut = () => {
    if (this.state.width > 1024) {
      if (!this.state.transition.reversed) {
        this.state.transition.reverse()
        this.state.transition.play()
        this.state.transitionPre.reverse()
        this.state.transitionOverlay.reverse()
        this.state.transitionPre.play()
        this.state.transitionImage.reverse()
        this.state.transitionImage.play()
        this.state.transitionOverlay.play()
        this.state.transitionArrow.reverse()
        this.state.transitionArrow.play()
      }
    }
  }

  calculateSize = () => {
    if (typeof window !== `undefined`) {
      this.setState({
        width: window.innerWidth,
      })
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener("resize", this.calculateSize)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.details.show === false) {
      if (!this.state.transitionInit.reversed) {
        this.state.transitionInit.reverse()
        this.state.transitionInit.play()
      }
    }
  }

  render() {
    const { link, pre, title, image, disabled } = this.props.details
    if (link.includes("https")) {
      return (
        <a
          href={disabled ? "#!" : `${link}/`}
          target="_blank"
          className={[styles.item, disabled ? styles.itemDisabled : null].join(
            " "
          )}
          onMouseEnter={this.hoverIn}
          onMouseLeave={this.hoverOut}
          ref="item"
        >
          <span
            className={styles.pre}
            dangerouslySetInnerHTML={{ __html: pre }}
          />
          <h1
            className={[styles.title, disabled ? styles.disabled : null].join(
              " "
            )}
          >
            {title.map((row, i) => {
              return (
                <React.Fragment key={i}>
                  <span>{row}</span>
                  {title.length === i + 1 ? (
                    <ArrowRight className={styles.arrow} />
                  ) : null}
                </React.Fragment>
              )
            })}
          </h1>
          <div className={styles.image}>
            <span className={styles.overlay}></span>
            {image.localFile && (
              <Image
                className={styles.background}
                fluid={image.localFile.childImageSharp.fluid}
              />
            )}
          </div>
        </a>
      )
    } else {
      return (
        <Link
          to={disabled ? "#!" : `${link}/`}
          className={[styles.item, disabled ? styles.itemDisabled : null].join(
            " "
          )}
          onMouseEnter={this.hoverIn}
          onMouseLeave={this.hoverOut}
          onClick={e => this.click(e.currentTarget)}
          ref="item"
        >
          <span
            className={styles.pre}
            dangerouslySetInnerHTML={{ __html: pre }}
          />
          <h1
            className={[styles.title, disabled ? styles.disabled : null].join(
              " "
            )}
          >
            {title.map((row, i) => {
              return (
                <React.Fragment key={i}>
                  <span>{row}</span>
                  {title.length === i + 1 ? (
                    <ArrowRight className={styles.arrow} />
                  ) : null}
                </React.Fragment>
              )
            })}
          </h1>
          <div className={styles.image}>
            <span className={styles.overlay}></span>
            {/* <span
              className={styles.background}
              style={{ backgroundImage: `url(${image})` }}
            ></span> */}
            {image.localFile && (
              <Image
                className={styles.background}
                fluid={image.localFile.childImageSharp.fluid}
              />
            )}
          </div>
        </Link>
      )
    }
  }
}

export default NavigationItem
